export { default as AboutPosts } from '../../components/AboutPosts.vue'
export { default as Accordion } from '../../components/Accordion.vue'
export { default as AccordionItem } from '../../components/AccordionItem.vue'
export { default as Advantages } from '../../components/Advantages.vue'
export { default as AdvantagesItem } from '../../components/AdvantagesItem.vue'
export { default as Benefits } from '../../components/Benefits.vue'
export { default as BestOfferBox } from '../../components/BestOfferBox.vue'
export { default as BestOffers } from '../../components/BestOffers.vue'
export { default as BestOffersCard } from '../../components/BestOffersCard.vue'
export { default as Brands } from '../../components/Brands.vue'
export { default as BrandsList } from '../../components/BrandsList.vue'
export { default as Chars } from '../../components/Chars.vue'
export { default as ComparatorCard } from '../../components/ComparatorCard.vue'
export { default as Compare } from '../../components/Compare.vue'
export { default as ContactUs } from '../../components/ContactUs.vue'
export { default as ContactUsGeneral } from '../../components/ContactUsGeneral.vue'
export { default as ContactUsSingle } from '../../components/ContactUsSingle.vue'
export { default as Curve } from '../../components/Curve.vue'
export { default as Doubts } from '../../components/Doubts.vue'
export { default as DoubtsGeneral } from '../../components/DoubtsGeneral.vue'
export { default as DoubtsSingle } from '../../components/DoubtsSingle.vue'
export { default as Equipment } from '../../components/Equipment.vue'
export { default as EquipmentItem } from '../../components/EquipmentItem.vue'
export { default as FaqModule } from '../../components/FaqModule.vue'
export { default as FilterAccordion } from '../../components/FilterAccordion.vue'
export { default as Filters } from '../../components/Filters.vue'
export { default as FlexCard } from '../../components/FlexCard.vue'
export { default as FlexContactUs } from '../../components/FlexContactUs.vue'
export { default as FlexOffers } from '../../components/FlexOffers.vue'
export { default as Footer } from '../../components/Footer.vue'
export { default as GetNowOnTop } from '../../components/GetNowOnTop.vue'
export { default as GetOnline } from '../../components/GetOnline.vue'
export { default as Header } from '../../components/Header.vue'
export { default as Hero } from '../../components/Hero.vue'
export { default as HeroHome } from '../../components/HeroHome.vue'
export { default as HeroHomeSlider } from '../../components/HeroHomeSlider.vue'
export { default as HeroRenting } from '../../components/HeroRenting.vue'
export { default as InterestYou } from '../../components/InterestYou.vue'
export { default as InterestYouItem } from '../../components/InterestYouItem.vue'
export { default as LaravelPagination } from '../../components/LaravelPagination.vue'
export { default as LowerOffers } from '../../components/LowerOffers.vue'
export { default as LowerOffersCard } from '../../components/LowerOffersCard.vue'
export { default as NoResult } from '../../components/NoResult.vue'
export { default as NuxtLogo } from '../../components/NuxtLogo.vue'
export { default as Offers } from '../../components/Offers.vue'
export { default as OffersHeaderCar } from '../../components/OffersHeaderCar.vue'
export { default as Pagination } from '../../components/Pagination.vue'
export { default as RentingCar } from '../../components/RentingCar.vue'
export { default as RentingModule } from '../../components/RentingModule.vue'
export { default as Services } from '../../components/Services.vue'
export { default as ServicesItem } from '../../components/ServicesItem.vue'
export { default as SmallContact } from '../../components/SmallContact.vue'
export { default as Spinner } from '../../components/Spinner.vue'
export { default as TextModule } from '../../components/TextModule.vue'
export { default as TopBar } from '../../components/TopBar.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
