<template>
  <div class="relative overflow-hidden transition-all duration-500 card carCard" v-if="offer && offer.car">
    <div class="half-background shine-overlay">
      <div class="shine"></div>
    </div>
    <div class="flex flex-row justify-between px-3 mx-3 card-top"
      :class="offer.car.dgtLabel !== 'Z' && offer.car.dgtLabel !== 'E' ? 'justify-end' : 'justify-between'">
      <img v-if="offer.car.dgtLabel === 'Z'" class="inline-block emission-img" src="~assets/images/0.png"  alt="Z"/>
      <img v-if="offer.car.dgtLabel === 'E'" class="inline-block mb-2 emission-img" src="~assets/images/eco.png"  alt="E"/>
      <!--<img v-if="offer.car.dgtLabel === 'C'" class="inline-block mb-2 emission-img" src="~assets/images/c.png" />-->
      <span class="tag yellow-tag capitalize" v-if="offer.car.vehicleStatus === 'NUEVO'" >Nuevo</span>
      <span class="tag blue-tag capitalize" v-else>Seminuevo</span>
      <div>
        <span class="compare compare--mobile" @click="removeFromCompare" v-if="isInCompare">No comparar</span>
        <span class="compare compare--mobile" @click="addToCompare" v-if="!isInCompare">
          <nuxt-img class="inline-block" src="~assets/images/icons/compare-icon.png" />
          Comparar
        </span>
      </div>
    </div>
    <div class="img-offer'">
      <NuxtLink :to="`/ofertas/${transType(offer.car.vehicleStatus)}/detalle/${offer.car.slug}`">
        <img :src="offer.offerDetail.urlPhoto" :class="offer.car.vehicleStatus === 'NUEVO' ? 'card-img' : 'card-img-n'"  alt=""/>
      </NuxtLink>
    </div>

    <div class="h-full card-body justify-between pt-3">
      <div class="mb-3 card-divider md:hidden"></div>
      <div class="brand-model">
        <NuxtLink :to="`/ofertas/${transType(offer.car.vehicleStatus)}/detalle/${offer.car.slug}`">
          {{ offer.car.brand.name }} {{ offer.car.model }}
          <div class="fuel version">{{ offer.car.version }}</div>
        </NuxtLink>
      </div>
      <div class="fuel">
        <nuxt-img class="inline-block" src="~assets/images/icons/fuel-icon.png" />
        <span>{{ offer.car.fuelType }}</span>
      </div>
      <div class="my-3 md:my-2 card-divider"></div>
      <div class="flex flex-row md:flex-col justify-start gap-x-2">
        <p class="from">Desde</p>
        <div class="flex flex-row justify-between md:w-full">
          <span class="price" v-if="offer?.type === 'particular'">{{ formatNumber(offer.offerDetail.monthFee) }}€/mes</span>
          <span class="price" v-else>{{ formatNumber(offer.offerDetail.monthFeeWithoutTaxes) }}€/mes</span>
          <span class="compare compare--desktop" @click="removeFromCompare" v-if="isInCompare">No comparar</span>
          <span class="compare compare--desktop" @click="addToCompare" v-if="!isInCompare">
            <nuxt-img class="inline-block" src="~assets/images/icons/compare-icon.png" />
            Comparar
          </span>
        </div>
        <p class="from" v-if="offer?.type === 'particular'">IVA incluido</p>
        <p class="from" v-else>IVA no incluido</p>
      </div>
      <div class="mt-2 card-divider max-md:hidden"></div>
    </div>

   <!-- <div v-if="offer.car.vehicleStatus == 'USADO'" class="absolute bottom-0 left-0 right-0 text-white carburante">
      <b>250</b>€ de carburante de regalo
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'BestOffersCard',
  props: {
    offer: Object,
  },
  data() {
    return {}
  },
  methods: {
    transType(type) {
      return type === 'NUEVO' ? 'nuevo' : 'redrive';
    },
    formatNumber(x) {
      if (!x) {
        return '';
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    removeFromCompare() {
      this.$store.dispatch('offers/removeFromCompare', this.offer)
    },
    addToCompare() {
      this.$store.dispatch('offers/addToCompare', this.offer)
    },
  },
  computed: {
    isInCompare() {
      return this.$store.getters['offers/isInCompare'](this.offer.id)
    },
  },
}
</script>

<style scoped lang="scss">
.from {
  font-family: Source Sans Pro;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04em;
  // margin-bottom: 4px;
}

.card {
  padding-bottom: 0;
}

.card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.25rem 1.25rem 1.75rem 1.25rem;
  padding-bottom: 35px;

  @media (max-width: 767px) {
    justify-content: flex-start;
    padding: 10px;
  }
  .brand-model {
    font-family: Chillax;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .fuel {
    font-family: Source Sans Pro;
    display: flex;
    align-items: center;
    column-gap: 2px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .price {
    font-family: Chillax;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
}

.emission-img {
  max-width: 40px;
  width: 100%;
  margin-bottom: 0%;
}

.compare {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  column-gap: 2px;
  cursor: pointer;

  &--mobile {
    flex-direction: row-reverse;
    @media (min-width: 776px) {
      display: none;
    }
  }
  &--desktop {
    @media (max-width: 776px) {
      display: none;
    }
  }
}

.img-offer {
  height: 200px;
  margin-top: 25px;
  min-width: 250px;

  a {
    height: 200px;
    display: block;
    position: relative;
  }
}

.card-img {
  object-fit: contain;
  position: relative;
  height: 200px;
  margin: 0 auto;
}

.card-img-n {
  object-fit: contain;
  position: relative;
  // height: 200px;
  width: 100%;
}

.card-img-clipped {
  min-width: 250px;

  a {
    //   height: 200px;
    display: block;
    position: relative;
    /*   &:before {
      content: '';
      position: absolute;
      top: 0px;
      height: 20px;
      width: 100%;
      background-color: #ffffff;
      z-index: 3;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      height: 20px;
      width: 100%;
      background-color: #ffffff;
      z-index: 3;
    }*/
  }
}

.carCard {
  height: 490px;
  max-width: 300px;
  @media (max-width: 767px) {
    height: 420px;
  }
}

.card-top {
  min-height: 60px;
  align-items: center;

  .tag {
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
  }

  img {
    order: 1;
  }

  .vn-button {
    order: 0;
  }
}

.carburante {
  background: linear-gradient(90deg, #00b67a 0%, #44e1c3 100%);
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;

  b {
    font-family: Montserrat;
    font-size: 23px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.half-background{
  height: 200px;
  width: 100%;
  position: absolute;
  background-color: #73d2d2;
  z-index: -2;
}
.version {
  font-size: 14px!important;
}
</style>
