<template>
  <header
    class="sticky top-0 left-0 flex flex-col w-full header"
    @mouseleave="
      close()
      openSearch = false
      open = false
    "
  >
    <!-- green bar -->
    <nav class="relative z-20 flex justify-center bg-primary-cp first-nav px-10 text-white">
      <div
        @mouseenter="
          close()
          openSearch = false
        "
        :class="[
          'grid flex-row w-full py-3 grid-flow-col max-w-8xl grid-rows-2 gap-4 text-xs font-normal lg:flex lg:items-center lg:justify-end lg:gap-9 lg:font-semibold',
          { 'hidden lg:flex': !open },
        ]"
      >
        <NuxtLink to="/faq" class="lg:capitalize">Preguntas frecuentes</NuxtLink>
        <NuxtLink to="/sobre-nosotros" class="lg:capitalize"
          >Sobre nosotros</NuxtLink
        >
        <NuxtLink to="/contacto" class="text-center lg:capitalize">
          <button class="lg:capitalize">Contáctanos</button>
        </NuxtLink>
        <a
          href="tel:910554913"
          class="text-center flex items-center justify-center"
        >
          <img
            alt="phone icon"
            class="icon"
            src="~assets/images/icons/phone_white.svg"
          />
          <span class="call">910 554 913</span>
        </a>
      </div>
    </nav>
    <!-- white bar -->
    <nav
      class="relative z-20 flex justify-center bg-white white-bar px-10"
      @mouseleave="openSearch = false"
    >
      <div
        class="flex justify-between w-full max-h-[70px] max-w-8xl second-nav active-icon sec gap-x-4"
      >
        <div class="flex gap-x-14">
          <NuxtLink v-if="!openSearch" to="/" @click="open = false">
            <img
              alt="logo"
              class="logo"
              src="~/assets/images/icons/ald-logo.svg"
            />
          </NuxtLink>
          <div class="flex-row hidden gap-8 lg:flex m-links">
            <NuxtLink
              @mouseenter="open = false"
              class="h-full"
              to="/ofertas"
              :style="position === 0 ? { textUnderlineOffset: '53px;' } : ''"
              @click="open = false"
              v-if="!openSearch"
            >
              <div @mouseenter="open = false" class="text">Ofertas</div>
            </NuxtLink>
            <a class="h-full cursor-default" v-if="!openSearch">
              <div
                :class="
                  $route.path.includes('particulares')
                    ? 'nuxt-link-exact-active nuxt-link-active'
                    : ''
                "
                class="text"
                @click="linkClick(0, 1)"
              >
                Particulares
              </div>
            </a>
            <a class="h-full cursor-default" v-if="!openSearch">
              <div
                :class="
                  $route.path.includes('empresas')
                    ? 'nuxt-link-exact-active nuxt-link-active'
                    : ''
                "
                class="text"
                @click="linkClick(1, 0)"
              >
                Autónomos/Empresas
              </div>
            </a>
            <NuxtLink
              class="h-full text-secondary"
              to="/comparador"
              @click="close()"
              v-if="!isEmptyCompare && !openSearch"
            >
              <div class="text">
                Comparador ({{ $store.state.offers.compare.length }})
              </div>
            </NuxtLink>
          </div>
        </div>
        <div class="flex items-center gap-x-4">
          <div class="flex-row justify-end gap-8 flex m-links">
            <div
              class="flex items-center gap-3 search"
              v-click-outside="externalClick"
            >
              <input
                type="search"
                class="px-5 py-1 bg-gray-100 border border-[#0F3549] rounded-4xl"
                v-if="openSearch"
                :autofocus="openSearch ? 'autofocus' : ''"
                v-model="textSearch"
                @blur="openSearch = false"
                @mouseleave="openSearch = false"
              />
              <img
                class="cursor-pointer"
                src="~/assets/images/icons/search_2.svg"
                @click="openSearch = !openSearch"
                alt="search-icon"
              />
            </div>
          </div>
          <img
            v-if="!open"
            alt="menu"
            class="menu-icon"
            @click="toggleMenu"
            src="~assets/images/icons/menu-icon.svg"
          />
          <img
            v-else
            alt="menu"
            class="w-5 h-5 cursor-pointer"
            @click="toggleMenu"
            src="~assets/images/icons/close-new.svg"
          />
        </div>
      </div>
    </nav>
    <!-- mega menu -->
    <nav
      :class="[
        'h-full w-full overflow-auto lg:h-auto lg:absolute lg:top-full xl:flex xl:justify-center max-w-full left-0 flex-col p-0 second-nav mega-menu lg:py-16 z-10 myshadow',
        { flex: open },
        { hidden: !open },
        { hide: !open },
      ]"
      v-if="open"
    >
      <div class="nav-links-mobile flex flex-col gap-1 px-7 pb-6 lg:hidden">
        <!-- <div
          class="flex items-center gap-3 search mb-5"
          v-click-outside="externalClick"
        >
          <input
            type="search"
            class="px-5 py-1 bg-gray-100 border border-[#0F3549] rounded-4xl"
            :autofocus="openSearch ? 'autofocus' : ''"
            v-model="textSearch"
          />
          <img
            class="mr-4 cursor-pointer"
            src="~/assets/images/icons/search_2.svg"
            alt="search-icon"
          />
        </div> -->
        <div
          class="flex flex-col border-t divide-y xl:mx-auto lg:flex-row lg:justify-evenly lg:divide-y-0 lg:border-0 xl:max-w-8xl xl:w-full mb-5"
        ></div>
        <a class="nav-links-mobile--link" @click="goToRoute()">Ofertas</a>
        <a
          @click="goTo(0)"
          :class="[
            position === 0 ? 'active' : '',
            'nav-links-mobile--link flex justify-between w-full items-center',
          ]"
        >
          <span>Particulares</span>
          <img
            :class="[
              'w-4 h-4 duration-[0.3s]',
              { 'rotate-180': position === 0 },
            ]"
            src="~assets/images/icons/arrow-down.svg"
            alt="Arrow down"
          />
        </a>
        <a
          @click="goTo(1)"
          :class="[
            position === 1 ? 'active' : '',
            'nav-links-mobile--link flex justify-between w-full items-center',
          ]"
        >
          <span>Empresas</span>
          <img
            :class="[
              'w-4 h-4 duration-[0.3s]',
              { 'rotate-180': position === 1 },
            ]"
            src="~assets/images/icons/arrow-down.svg"
            alt="Arrow down"
          />
        </a>
        <NuxtLink
          @click="open = false"
          class="text-secondary"
          to="/comparador"
          v-if="!isEmptyCompare"
          >Comparador ({{ $store.state.offers.compare.length }})</NuxtLink
        >
      </div>
      <div
        :class="[
          'flex flex-col border-t divide-y xl:mx-auto lg:flex-row lg:justify-evenly lg:divide-y-0 lg:border-0 xl:max-w-8xl xl:w-full px-7 py-1',
          { 'bg-[#F6F6F6]': isMobile },
        ]"
      >
        <div
          @click="open = false"
          class="open-nav flex flex-col gap-5 py-3"
          v-for="(menu, i) in megaMenu[position]"
          :key="`menu-${i}`"
        >
          <div v-if="!isMobile" class="open-nav--title">
            <NuxtLink v-if="menu.link" :to="menu.link">{{
              menu.title
            }}</NuxtLink>
            <span
              class="open-nav--title"
              v-if="!menu.link && !menu.onMobile && !isMobile"
              >{{ menu.title }}</span
            >
          </div>
          <div class="flex flex-col gap-2">
            <NuxtLink
              class="open-nav--link"
              :to="item.link"
              v-for="(item, j) in menu.items"
              :key="`menu-${i}-item-${j}`"
            >
              {{ item.title }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </nav>
    <Compare />
  </header>
</template>

<script>
import OffersHeader from '~/components/Compare'
import Comparator from '~/pages/comparador'
import Compare from '~/components/Compare'
import vClickOutside from 'v-click-outside'
export default {
  name: 'Header',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { Compare, Comparator, OffersHeader },
  data() {
    return {
      time: null,
      position: -1,
      open: false,
      openSearch: false,
      textSearch: '',
      megaMenu: [
        // particulares
        [
          {
            title: 'Ofertas',
            items: [
              {
                title: 'Vehículo nuevo',
                link: '/particulares/vehiculos-nuevos',
                class: 'child',
              },
              {
                title: 'Vehículo seminuevo - ReDrive',
                link: '/particulares/redrive',
                class: 'child',
              },
            ],
          },
          // Conoce nuestro renting
          {
            title: 'Conoce nuestro renting',
            onMobile: false,
            items: [
              {
                title: '¿Qué renting necesito?',
                link: '/que-renting-necesito',
              },
              /* {
                 title: 'Qué incluye el renting ALD',
                 link: '/particulares/vehiculos-nuevos/servicios',
               },
               {
                 title: 'Ventajas del renting',
                 link: '/particulares/vehiculos-nuevos/ventajas',
               },*/
            ],
          },
        ],
        [
          // empresas
          {
            title: 'Ofertas',
            items: [
              {
                title: 'Vehículo nuevo',
                link: '/empresas/vehiculos-nuevos',
                class: 'child',
              },
              {
                title: 'Renting Flexible – Ayvens Flex',
                link: '/empresas/ayvens-flex',
                class: 'child',
              },
            ],
          },
          // Conoce nuestro renting
          {
            title: 'Conoce nuestro renting',
            items: [
              {
                title: '¿Qué renting necesito?',
                link: '/que-renting-necesito',
              },
              /*   {
                   title: 'Qué incluye el renting ALD',
                   link: '/empresas/vehiculos-nuevos/servicios',
                 },
                 {
                   title: 'Ventajas del renting',
                   link: '/empresas/vehiculos-nuevos/ventajas',
                 },*/
            ],
          },
        ],
      ],
    }
  },
  mounted() {
    this.getOfferToCompare()
  },
  computed: {
    isEmptyCompare() {
      return this.$store.getters['offers/isEmptyCompare']()
    },
    isMobile() {
      return window !== 'undefined' && window.screen.width < 992
    },
    letterSpace() {
      return this.isMobile ? '0' : '0.06em'
    },
    phoneIconImage() {
      return this.isMobile
        ? '~assets/images/icons/phone-mobile.svg'
        : '~assets/images/icons/phone.svg'
    },
  },
  methods: {
    linkClick(pos, pos2) {
      if (this.position === pos2) {
        this.open = false
        this.open = true
        this.goTo(pos)
      } else {
        this.open = !this.open
        this.goTo(pos)
      }
    },
    externalClick(event) {
      this.openSearch = false
    },
    toggleMenu() {
      this.open = !this.open
      // this.goTo(0)
    },
    goTo(position) {
      this.position = position
    },
    goToRoute(route) {
      this.open = !this.open
      this.$router.push(`/ofertas`)
    },
    close() {
      this.position = -1
    },
    async getOfferToCompare() {
      await this.$store.dispatch('user/login').then(async (res) => {
        await this.$store.dispatch('offers/getOfferToCompare')
      })
    },
  },
  watch: {
    async textSearch(val) {
      clearTimeout(this.time)
      this.time = setTimeout(async () => {
        if (val === '') {
          await this.$store.dispatch('offers/clearSearch')
          await this.$router.push(`/`)
        } else if (val.length >= 3) {
          await this.$store.dispatch('user/login').then(async (res) => {
            await this.$store.dispatch('offers/search', val)
            await this.$router.push(`/search/${val}`)
          })
        }
      }, 1000);
    },
  },
}
</script>

<style scoped lang="scss">
.hide {
  display: none !important;
}
.header {
  z-index: 10000;
  .logo {
    @media (min-width: 1440px) {
      margin: 0;
    }
    max-height: 90px;
    margin-left: 0px;
  }
  .first-nav {
    a {
      letter-spacing: v-bind(letterSpace);
      // letter-spacing: 0.06em;
      // text-transform: uppercase;
      line-height: 26px;
    }

    button {
      width: 133px;
      height: 27px;
      left: 1126px;
      top: 11px;
      border: 1px solid white;
      border-radius: 100px;
    }

    .call {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.06em;
      // text-transform: uppercase;
      margin-left: 10px;
    }
  }
  .sec {
    height: 118px;
  }
  .white-bar {
    // box-shadow: 0 4px 32px rgba(5, 11, 127, 0.2);
    @media (min-width: 1440px) {
      padding-left: 50px;
    }
  }
  .myshadow {
    // box-shadow: 0 4px 32px rgba(5, 11, 127, 0.2);
  }
  .second-nav {
    a {
      // font-style: normal;
      // font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      height: 100%;
      // max-width: 270px;
      // padding-bottom: 4px;

      .text {
        font-family: Chillax Semibold;
        @apply h-full flex items-center;
        cursor: pointer;
      }
    }

    .menu-icon {
      @media (min-width: 1024px) {
        display: none;
      }
      height: 30px;
      width: 30px;
      margin-top: auto;
      margin-bottom: auto;
      // margin-right: 2rem;
      cursor: pointer;
    }
    .m-links {
      .nuxt-link-active {
        text-decoration: underline;
        text-decoration-color: $--secondary-color;
        text-decoration-thickness: 4px;
        text-underline-offset: 26px;
      }
    }
    &.active-icon {
      .nuxt-link-exact-active {
        text-decoration: underline;
        text-decoration-color: $--secondary-color;
        text-decoration-thickness: 4px;
        text-underline-offset: 26px;

        &.hover {
          // text-decoration-color: #a5eafc;
        }
      }
    }
  }
  .text-blue {
    text-decoration-color: #4391ff;
    color: #4391ff;
  }
  .mega-menu {
    @media (min-width: 1024px) {
      max-height: 60vh;
    }
    background: #fff;
    // backdrop-filter: blur(20px);

    &::before {
      @media (max-width: 1024px) {
        display: none;
      }
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      border-bottom: 2px solid #eaf8f8;
    }
    .open-nav {
      &--title {
        font-family: Chillax Semibold;
      }
      &--link {
        font-family: Chillax;
        position: relative;
        padding: 8px 0;
        &.nuxt-link-active::before {
          content: '';
          position: absolute;
          top: 0;
          left: -20px;
          height: 36px;
          width: 3px;
          background-color: $--secondary-color;
        }
      }
    }
  }
  .nav-links-mobile {
    &--link {
      font-family: Chillax Semibold;
      font-size: 18px;
      padding: 8px 0;
      &.active {
        color: $--secondary-color;
      }
    }
  }
}
</style>
