<template xmlns="http://www.w3.org/1999/html">
  <div>
     <div class="rside" v-if="subtype !== 'ald'">
    <!--  <button class="vn-button vn-green" v-else>Seminuevo</button>-->
      <p class="brand-model">
        <NuxtLink to="/ofertas/1">Seat </NuxtLink>
      </p>
      <p class="brand-model">
        <NuxtLink to="/ofertas/1">IBIZA </NuxtLink>
      </p>
      <p class="from">Desde</p>
      <p class="price">
        <span v-if="type === 'particular' && subtype !== 'redrive' ">314</span>
        <span v-if="type === 'particular' && subtype === 'redrive'">295</span>
        <span  v-if="type !== 'particular'">287</span> <span class="price-label">€/mes<span v-if="subtype !== 'redrive'">*</span></span>
      </p>
      <div class="mt-2">
         <img class="inline-block fuel-icon" src="~assets/images/icons/fuel-icon.png" alt="">
        <span class="price-label fuel-text"><span v-if="type === 'particular' && subtype === 'redrive'">Gasolina</span><span v-else>Gasolina</span></span>
      </div>
    </div>
   <img v-if="subtype !== 'ald'"  class="home-car-img" src="~assets/images/home-car-desk-full.png"  alt=""/>
    <ul class="mt-5 pt-5 car-list">
      <li v-for="item in info" class="mb-3 list-disc">{{ item }}</li>
    </ul>
    <NuxtLink v-if="seeInfo" to="/empresas/ayvens-flex"> <button class="see-all more mb-3 mt-5">Más información</button></NuxtLink>

   <p class="pb-5 pt-3"  v-if="subtype !== 'redrive'">*Oferta válida hasta fin de existencias</p>
  </div>
</template>

<script>
export default {
  name: "RentingCar",
  props: {
    subtype: {
      type: String
    },
    type: {
      type: String
    }
  },
  data(){
    return {
      vnText: [
        'Estrena coche con o sin desembolso inicial',
        'Contratos a tu medida (36, 48 o 60 meses)',
        'Ahorra en carburante con los modelos mas eficientes',
        'Controla los gastos gracias a una única cuota',
        'Ahorra tiempo realizando todas las gestiones con Ayvens',
        'Colabora con el medioambiente gracias a vehículos más ecológicos'
     ],
      voText: [
        'Renting de seminuevos revisados y con historial de mantenimiento',
        'Devolución en 15 días o 1.000 km si no te convence',
        'Contrato de 12 meses sin permanencia',
        'Tranquilidad ya que cuentas con todos los servicios incluidos',
        'Contratación 100% online',
        'Si lo quieres puedes comprarlo'
      ],
      vnEText: [
        'Estrena coche con o sin desembolso inicial',
        'Contratos a tu medida (36, 48 o 60 meses)',
        'Ahorra en carburante con los modelos mas eficientes',
        'Controla los gastos gracias a una única cuota',
        'Ahorra tiempo realizando todas las gestiones con Ayvens',
        'Colabora con el medioambiente gracias a vehículos más ecológicos'

      ],
      aldText: [
        'Flexibilidad pudiendo cambiar de segmento cada mes',
        'Contratos a tu medida (desde 1 a 24 meses)',
        'Ahorra con una cuota mensual sin desembolso inicial',
        'Sin penalización de permanencia a partir del primer mes',
        'Disponibilidad inmediata de vehículos seminuevos revisados',
        'Flota actualizada con los últimos modelos'

      ]
    }
  },
  computed: {
    info(){
      if(this.subtype === 'newP') return this.vnText
      if(this.subtype === 'newE') return this.vnEText
      if(this.subtype === 'ald') return this.aldText
      if(this.subtype === 'redrive') return this.voText
    },
    seeInfo(){
      return this.subtype === 'ald'
    }
  }
}
</script>

<style scoped lang="scss">
.brand-model {
  font-family: Chillax;

}
#tag-image {
  margin-left: 300px;
  margin-top: -50px;
  width: 90px;
  right: 0;
  z-index: 100;
  height: auto!important;
  position: relative;

  @media (min-width: 768px) {
    transform: translateX(-50px);
    position: relative;
  }
  @media (max-width: 768px) {
    transform: translateX(150px);
    position: relative;
  }
  @media (max-width: 600px) {
    transform: translateX(0);
    position: relative;
  }
  @media (max-width: 500px) {
    transform: translateX(-40px);
    position: relative;
    margin-top: -10px;
  }
  @media (min-width: 1200px) {
    transform: translateX(120px)!important;
    position: relative;
  }

}
.rside {
  position: absolute;
  overflow-x: visible;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  background: #FFFFFF;
  margin-top: 30px;
  padding: 50px;
  padding-right: 40px;
  margin-left: 30px;
  transform: translateY(-100px);
  @media (max-width: 769px) {
    justify-items: center;
    margin-left: 10px;
    transform: none;
  }
  .vn-button{
    font-size: 12px;
  }
}
.home-car-img {
  position: relative;
  z-index: 1000;
  transform: translateY(70px);
  @media (min-width: 767px) and (max-width: 1024px) {
    transform: translateY(90px);
  }
  @media (max-width: 767px) {
    object-fit: contain!important;
    transform: translateY(140px);
  }
  @media (max-width: 600px) {
    transform: translateY(200px);
  }
}
.price {
  font-family: Chillax;
  font-style: normal;
  font-weight: 700;
  font-size: 46.4286px;
  text-transform: uppercase;
  // color: #050B7F;
  @media (max-width: 767px) {
    font-size: 36px;
  }
}
.price-label{
  // font-family: Chillax;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-transform: none !important;
  // color: #050B7F;
  transform: translateY(-6px);
}
.from{
  // font-family: 'Source Sans Pro';
  font-weight: 400;
  // color: #050B7F;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  &.bold {
    font-weight: 700;
  }
}
.see-all {
  width: 278px;
  height: 62px;
  left: 581px;
  top: 2176px;
  background: $--secondary-color;
  border-radius: 100px;
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
}
.fuel-text{
  font-size: 16px!important;
  display: inline-block;
  transform: translateY(7px);
}
.fuel-icon{
   margin-top: 10px;
}
.car-list{
  padding-left: 10%;
  padding-right: 10%;;
  @media (max-width: 768px) {
    margin-top: 120px;
  }
  @media (max-width: 600px) {
    margin-top: 200px;
  }
}
</style>
