var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass: "sticky top-0 left-0 flex flex-col w-full header",
      on: {
        mouseleave: function ($event) {
          _vm.close()
          _vm.openSearch = false
          _vm.open = false
        },
      },
    },
    [
      _c(
        "nav",
        {
          staticClass:
            "relative z-20 flex justify-center bg-primary-cp first-nav px-10 text-white",
        },
        [
          _c(
            "div",
            {
              class: [
                "grid flex-row w-full py-3 grid-flow-col max-w-8xl grid-rows-2 gap-4 text-xs font-normal lg:flex lg:items-center lg:justify-end lg:gap-9 lg:font-semibold",
                { "hidden lg:flex": !_vm.open },
              ],
              on: {
                mouseenter: function ($event) {
                  _vm.close()
                  _vm.openSearch = false
                },
              },
            },
            [
              _c(
                "NuxtLink",
                { staticClass: "lg:capitalize", attrs: { to: "/faq" } },
                [_vm._v("Preguntas frecuentes")]
              ),
              _vm._v(" "),
              _c(
                "NuxtLink",
                {
                  staticClass: "lg:capitalize",
                  attrs: { to: "/sobre-nosotros" },
                },
                [_vm._v("Sobre nosotros")]
              ),
              _vm._v(" "),
              _c(
                "NuxtLink",
                {
                  staticClass: "text-center lg:capitalize",
                  attrs: { to: "/contacto" },
                },
                [
                  _c("button", { staticClass: "lg:capitalize" }, [
                    _vm._v("Contáctanos"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._m(0),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "nav",
        {
          staticClass:
            "relative z-20 flex justify-center bg-white white-bar px-10",
          on: {
            mouseleave: function ($event) {
              _vm.openSearch = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex justify-between w-full max-h-[70px] max-w-8xl second-nav active-icon sec gap-x-4",
            },
            [
              _c(
                "div",
                { staticClass: "flex gap-x-14" },
                [
                  !_vm.openSearch
                    ? _c(
                        "NuxtLink",
                        {
                          attrs: { to: "/" },
                          on: {
                            click: function ($event) {
                              _vm.open = false
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              alt: "logo",
                              src: require("assets/images/icons/ald-logo.svg"),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-row hidden gap-8 lg:flex m-links" },
                    [
                      !_vm.openSearch
                        ? _c(
                            "NuxtLink",
                            {
                              staticClass: "h-full",
                              style:
                                _vm.position === 0
                                  ? { textUnderlineOffset: "53px;" }
                                  : "",
                              attrs: { to: "/ofertas" },
                              on: {
                                mouseenter: function ($event) {
                                  _vm.open = false
                                },
                                click: function ($event) {
                                  _vm.open = false
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text",
                                  on: {
                                    mouseenter: function ($event) {
                                      _vm.open = false
                                    },
                                  },
                                },
                                [_vm._v("Ofertas")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.openSearch
                        ? _c("a", { staticClass: "h-full cursor-default" }, [
                            _c(
                              "div",
                              {
                                staticClass: "text",
                                class: _vm.$route.path.includes("particulares")
                                  ? "nuxt-link-exact-active nuxt-link-active"
                                  : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(0, 1)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Particulares\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.openSearch
                        ? _c("a", { staticClass: "h-full cursor-default" }, [
                            _c(
                              "div",
                              {
                                staticClass: "text",
                                class: _vm.$route.path.includes("empresas")
                                  ? "nuxt-link-exact-active nuxt-link-active"
                                  : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(1, 0)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Autónomos/Empresas\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isEmptyCompare && !_vm.openSearch
                        ? _c(
                            "NuxtLink",
                            {
                              staticClass: "h-full text-secondary",
                              attrs: { to: "/comparador" },
                              on: {
                                click: function ($event) {
                                  return _vm.close()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n              Comparador (" +
                                    _vm._s(
                                      _vm.$store.state.offers.compare.length
                                    ) +
                                    ")\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex items-center gap-x-4" }, [
                _c(
                  "div",
                  { staticClass: "flex-row justify-end gap-8 flex m-links" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.externalClick,
                            expression: "externalClick",
                          },
                        ],
                        staticClass: "flex items-center gap-3 search",
                      },
                      [
                        _vm.openSearch
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.textSearch,
                                  expression: "textSearch",
                                },
                              ],
                              staticClass:
                                "px-5 py-1 bg-gray-100 border border-[#0F3549] rounded-4xl",
                              attrs: {
                                type: "search",
                                autofocus: _vm.openSearch ? "autofocus" : "",
                              },
                              domProps: { value: _vm.textSearch },
                              on: {
                                blur: function ($event) {
                                  _vm.openSearch = false
                                },
                                mouseleave: function ($event) {
                                  _vm.openSearch = false
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.textSearch = $event.target.value
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "cursor-pointer",
                          attrs: {
                            src: require("assets/images/icons/search_2.svg"),
                            alt: "search-icon",
                          },
                          on: {
                            click: function ($event) {
                              _vm.openSearch = !_vm.openSearch
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.open
                  ? _c("img", {
                      staticClass: "menu-icon",
                      attrs: {
                        alt: "menu",
                        src: require("assets/images/icons/menu-icon.svg"),
                      },
                      on: { click: _vm.toggleMenu },
                    })
                  : _c("img", {
                      staticClass: "w-5 h-5 cursor-pointer",
                      attrs: {
                        alt: "menu",
                        src: require("assets/images/icons/close-new.svg"),
                      },
                      on: { click: _vm.toggleMenu },
                    }),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.open
        ? _c(
            "nav",
            {
              class: [
                "h-full w-full overflow-auto lg:h-auto lg:absolute lg:top-full xl:flex xl:justify-center max-w-full left-0 flex-col p-0 second-nav mega-menu lg:py-16 z-10 myshadow",
                { flex: _vm.open },
                { hidden: !_vm.open },
                { hide: !_vm.open },
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "nav-links-mobile flex flex-col gap-1 px-7 pb-6 lg:hidden",
                },
                [
                  _c("div", {
                    staticClass:
                      "flex flex-col border-t divide-y xl:mx-auto lg:flex-row lg:justify-evenly lg:divide-y-0 lg:border-0 xl:max-w-8xl xl:w-full mb-5",
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "nav-links-mobile--link",
                      on: {
                        click: function ($event) {
                          return _vm.goToRoute()
                        },
                      },
                    },
                    [_vm._v("Ofertas")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: [
                        _vm.position === 0 ? "active" : "",
                        "nav-links-mobile--link flex justify-between w-full items-center",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.goTo(0)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("Particulares")]),
                      _vm._v(" "),
                      _c("img", {
                        class: [
                          "w-4 h-4 duration-[0.3s]",
                          { "rotate-180": _vm.position === 0 },
                        ],
                        attrs: {
                          src: require("assets/images/icons/arrow-down.svg"),
                          alt: "Arrow down",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: [
                        _vm.position === 1 ? "active" : "",
                        "nav-links-mobile--link flex justify-between w-full items-center",
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.goTo(1)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("Empresas")]),
                      _vm._v(" "),
                      _c("img", {
                        class: [
                          "w-4 h-4 duration-[0.3s]",
                          { "rotate-180": _vm.position === 1 },
                        ],
                        attrs: {
                          src: require("assets/images/icons/arrow-down.svg"),
                          alt: "Arrow down",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isEmptyCompare
                    ? _c(
                        "NuxtLink",
                        {
                          staticClass: "text-secondary",
                          attrs: { to: "/comparador" },
                          on: {
                            click: function ($event) {
                              _vm.open = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "Comparador (" +
                              _vm._s(_vm.$store.state.offers.compare.length) +
                              ")"
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "flex flex-col border-t divide-y xl:mx-auto lg:flex-row lg:justify-evenly lg:divide-y-0 lg:border-0 xl:max-w-8xl xl:w-full px-7 py-1",
                    { "bg-[#F6F6F6]": _vm.isMobile },
                  ],
                },
                _vm._l(_vm.megaMenu[_vm.position], function (menu, i) {
                  return _c(
                    "div",
                    {
                      key: `menu-${i}`,
                      staticClass: "open-nav flex flex-col gap-5 py-3",
                      on: {
                        click: function ($event) {
                          _vm.open = false
                        },
                      },
                    },
                    [
                      !_vm.isMobile
                        ? _c(
                            "div",
                            { staticClass: "open-nav--title" },
                            [
                              menu.link
                                ? _c("NuxtLink", { attrs: { to: menu.link } }, [
                                    _vm._v(_vm._s(menu.title)),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !menu.link && !menu.onMobile && !_vm.isMobile
                                ? _c(
                                    "span",
                                    { staticClass: "open-nav--title" },
                                    [_vm._v(_vm._s(menu.title))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex flex-col gap-2" },
                        _vm._l(menu.items, function (item, j) {
                          return _c(
                            "NuxtLink",
                            {
                              key: `menu-${i}-item-${j}`,
                              staticClass: "open-nav--link",
                              attrs: { to: item.link },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.title) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Compare"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "text-center flex items-center justify-center",
        attrs: { href: "tel:910554913" },
      },
      [
        _c("img", {
          staticClass: "icon",
          attrs: {
            alt: "phone icon",
            src: require("assets/images/icons/phone_white.svg"),
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "call" }, [_vm._v("910 554 913")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }