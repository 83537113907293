var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("h1", { staticClass: "relative z-50 p-0 m-0 font-bold" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _vm._v(" "),
    _c("h1", { staticClass: "relative z-50 p-0 m-0 font-bold" }, [
      _vm._v(_vm._s(_vm.title2)),
    ]),
    _vm._v(" "),
    _c("h1", { staticClass: "relative z-50 p-0 m-0 font-bold" }, [
      _vm._v(_vm._s(_vm.title3)),
    ]),
    _vm._v(" "),
    _c("h3", { staticClass: "relative z-50 p-0 m-0 font-bold" }, [
      _vm._v(_vm._s(_vm.subtitle)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex" },
      [
        _vm.subtype !== "ald"
          ? _c(
              "NuxtLink",
              {
                attrs: {
                  id: "cta-renting-offers-link",
                  to: "/ofertas?type=" + _vm.type + "&status=" + _vm.status,
                },
              },
              [
                _c("button", { staticClass: "see-all more mb-3 mt-5" }, [
                  _vm._v("Ver Ofertas"),
                ]),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }