var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-regular" }, [
    _c(
      "div",
      { staticClass: "relative w-full px-8 pt-20 pb-14 reset-padding" },
      [
        _c(
          "div",
          {
            staticClass: "relative mx-auto",
            staticStyle: { "max-width": "1350px" },
          },
          [
            _c(
              "h2",
              { staticClass: "pb-3 mx-auto font-bold text-center h2-title" },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "pb-8 mx-auto text-center h3-subtitle" }, [
              _vm._v(_vm._s(_vm.subTitle)),
            ]),
            _vm._v(" "),
            !_vm.$store.state.offers.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3",
                  },
                  [
                    _vm._l(
                      _vm.$store.state.offers.lowerOffers.data,
                      function (offer) {
                        return _c("LowerOffersCard", {
                          key: offer.id,
                          attrs: { offer: offer },
                        })
                      }
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "container mx-auto back-shadow" }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.$store.state.offers.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "relative flex justify-center w-full mt-20 cta-box",
                  },
                  [
                    _vm.type && _vm.status
                      ? _c(
                          "NuxtLink",
                          {
                            staticClass: "cursor-pointer",
                            attrs: {
                              to: `/ofertas?type=${_vm.type}&status=${_vm.status}`,
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "see-all more",
                                attrs: { id: "cta-see-all-offers" },
                              },
                              [_vm._v("Ver todas las ofertas")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "see-all more-mobile",
                                attrs: { id: "cta-see-all-offers-mobile" },
                              },
                              [_vm._v("Más ofertas")]
                            ),
                          ]
                        )
                      : _c(
                          "NuxtLink",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { to: "/ofertas" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "see-all more",
                                attrs: { id: "cta-see-all-offers" },
                              },
                              [_vm._v("Ver todas las ofertas")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "see-all more-mobile",
                                attrs: { id: "cta-see-all-offers-mobile" },
                              },
                              [_vm._v("Más ofertas")]
                            ),
                          ]
                        ),
                  ],
                  1
                )
              : _c("Spinner"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }