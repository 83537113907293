var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "flex flex-col items-center px-8 lg:px-24" },
    [
      _c("script", {
        attrs: {
          id: "Cookiebot",
          src: "https://consent.cookiebot.com/uc.js",
          "data-cbid": _vm.cbKey,
          type: "text/javascript",
          "data-blockingmode": "auto",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "relative grid w-full grid-cols-2 pt-14 lg:pt-20 gap-x-5 lg:grid-cols-4 pb-14 max-w-8xl lg:gap-x-3",
        },
        [
          _c(
            "div",
            {
              staticClass: "relative second-column",
              staticStyle: { "z-index": "1000" },
            },
            [
              _c("ul", { staticClass: "flex flex-col gap-3" }, [
                _c(
                  "li",
                  [
                    _c("NuxtLink", { attrs: { to: "/ofertas" } }, [
                      _vm._v("Ofertas"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("NuxtLink", { attrs: { to: "/faq" } }, [
                      _vm._v("Preguntas frecuentes"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("NuxtLink", { attrs: { to: "/sobre-nosotros" } }, [
                      _vm._v("Sobre nosotros"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("NuxtLink", { attrs: { to: "/contacto" } }, [
                      _vm._v("Contacto"),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "hidden third-column lg:flex",
              staticStyle: { "z-index": "1000" },
            },
            [
              _c("ul", { staticClass: "flex flex-col" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "NuxtLink",
                      {
                        staticClass: "mt-3",
                        attrs: { to: "/que-renting-necesito" },
                      },
                      [_vm._v("¿Qué renting necesito?")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "NuxtLink",
                      {
                        staticClass: "mt-3",
                        attrs: { to: "/particulares/vehiculos-nuevos" },
                      },
                      [_vm._v("Vehículo nuevo")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "NuxtLink",
                      {
                        staticClass: "mt-3",
                        attrs: { to: "/particulares/redrive" },
                      },
                      [_vm._v("Vehículo seminuevo ReDrive")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "NuxtLink",
                      {
                        staticClass: "mt-3",
                        attrs: { to: "/empresas/vehiculos-nuevos" },
                      },
                      [_vm._v("Vehículo nuevo")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c(
                      "NuxtLink",
                      {
                        staticClass: "mt-3",
                        attrs: { to: "/empresas/ayvens-flex" },
                      },
                      [_vm._v("Renting Flexible-Ayvens Flex")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "relative third-column" }, [
            _c("ul", { staticClass: "flex flex-col" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("NuxtLink", { staticClass: "mt-4", attrs: { to: "/" } }, [
                    _c("span", [
                      _vm._v("Ctra. de Pozuelo, 32 "),
                      _c("br"),
                      _vm._v(" 28220 Majadahonda "),
                      _c("br"),
                      _vm._v(" Madrid España"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _vm._m(6),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(7),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-start-1 col-end-3 lg:col-start-2 lg:col-end-4 sub-links",
            },
            [
              _c(
                "ul",
                {
                  staticClass: "flex flex-row justify-center lg:justify-start",
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "NuxtLink",
                        {
                          staticClass: "pr-1 mr-1 lg:pr-4 lg:mr-4 mt-3 line",
                          attrs: { to: "/informacion-legal" },
                        },
                        [_vm._v("Aviso legal")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "NuxtLink",
                        {
                          staticClass: "pr-1 mr-1 lg:pr-4 lg:mr-4 mt-3 line",
                          attrs: { to: "/politica-de-privacidad" },
                        },
                        [_vm._v("Política de privacidad")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "NuxtLink",
                        {
                          staticClass: "mt-3",
                          attrs: { to: "/politica-de-cookies" },
                        },
                        [_vm._v("Política de cookies")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          _vm._m(9),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("p", [_vm._v("Conoce nuestro renting")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "mt-4" }, [
      _c("p", { staticClass: "plabel" }, [_vm._v("Renting particulares")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "mt-4" }, [
      _c("p", { staticClass: "plabel" }, [_vm._v("Renting empresas")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("p", [_vm._v("Contacto")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { staticClass: "mt-4", attrs: { href: "tel:910554913" } }, [
        _c("span", [
          _vm._v("Solicita una oferta "),
          _c("br"),
          _vm._v(" 910 554 913"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { staticClass: "mt-4", attrs: { href: "tel:900 300 360" } }, [
        _c("span", [
          _vm._v("Solicita una oferta Ayvens Flex "),
          _c("br"),
          _vm._v(" 900 300 360"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "mt-4",
          attrs: { href: "mailto:atencion.cliente@ayvens.com" },
        },
        [
          _c("span", { staticClass: "flex flex-col text-[#73D2D2]" }, [
            _c("span", [_vm._v("atencion.cliente@ayvens.com")]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "last-column flex flex-col gap-y-5 max-lg:col-span-2 max-lg:mt-5",
      },
      [
        _c(
          "a",
          {
            staticClass: "flex items-center gap-x-4",
            attrs: {
              href: "https://online.ayvens.es/clientes",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "w-8 h-8",
              attrs: {
                src: require("assets/images/icons/ald-icon-white.svg"),
                alt: "ald icon",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("My Ayvens - Clientes")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "flex items-center gap-x-4",
            attrs: {
              href: "https://online.ayvens.es/conductores",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "w-8 h-8",
              attrs: {
                src: require("assets/images/icons/ald-icon-white.svg"),
                alt: "ald icon",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("My Ayvens - Conductores")]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "social-links flex justify-end items-end gap-x-3 p-4" },
      [
        _c(
          "a",
          { attrs: { href: "https://twitter.com/AyvensES", target: "_blank" } },
          [
            _c("img", {
              staticClass: "w-4 h-4",
              attrs: {
                src: require("assets/images/icons/twitter-dark.svg"),
                alt: "X",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.linkedin.com/company/ayvens",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "w-4 h-4",
              attrs: {
                src: require("assets/images/icons/linkedin-dark.svg"),
                alt: "LinkedIn",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.youtube.com/@Ayvens",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "w-5 h-5",
              attrs: {
                src: require("assets/images/icons/youtube-dark.svg"),
                alt: "YouTube",
              },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "flex justify-start col-start-1 col-end-3 lg:col-start-2 lg:col-end-5 mt-5 lg:mt-0",
      },
      [_c("p", { staticClass: "copy-right" }, [_vm._v("© 2024 Ayvens")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }